.form-control:focus {
  border-color: rgb(251, 188, 5);
  box-shadow: inset 0 1px 1px rgba(251, 153, 5, 0.075), 0 0 8px rgba(251, 188, 5, 0.6);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgb(251, 188, 5) !important;
  --bs-btn-border-color: #FBBC05 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fba905;
  --bs-btn-hover-border-color: #fba905;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fba905;
  --bs-btn-active-border-color: rgb(251, 153, 5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #FBBC05 !important;
  --bs-btn-disabled-border-color: #FBBC05 !important;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #fba905;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fba905;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #fba905;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: none;
}
