/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
$primaryColor: '#FBBC05';
$theme-colors: (
  "primary": #FBBC05,
  "danger": #ff4136
);
$primary: #FBBC05;
@import '~bootstrap/scss/bootstrap';

* {
  font-family: 'Poppins';
}

html, body {
  height: 100%;
}
::-webkit-scrollbar {
  width: 0px;
}
body {
  background-color: #f8f9fa !important;
  font-size: 14px;

}
.md-drppicker.shown.drops-down-left {
  transform-origin: 100% 0;
  top: auto;
  left: -160px;
  right: auto;
}

.po-pumkm {
  font-size: 14px;
  padding: 1px !important;
}

.popover-body {
  padding: 12px;
}

.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color:#FBBC05;
}

.wrapper-filter-date-range {
  border: 1px solid #ECE9F1;
  border-radius: 4px;
  cursor: pointer;
}
.dropdown-item.active {
    color: var(--grayscale-dark-gray, #626262) !important;
    text-decoration: none !important;
    background-color: var(--komship-primary-bg, #F8ECC8) !important;
}

.wrapper-filter-leaderboard {
  background: #F8F8F8;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08);
  position: absolute;
  margin-left: -200px;
  width: 327px;
  z-index: 9;
}
.apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #e3e3e3;
  background: rgba(255,255,255,.96) !important;
  padding: 10px !important;
  width: 150px !important;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06) !important;
}
.apexcharts-tooltip.apexcharts-theme-light div span {
  font-style: normal !important;
  font-weight: 400 !important;
}
.apexcharts-data-labels {
  display: none !important;
}
.apexcharts-xaxistooltip {
    display: none !important;
}
@media only screen and (max-width: 767px) {
  body {
    background-color: #f8f9fa !important;
    font-size: 14px;

  }
}

.card-header{
  border-bottom: none !important;
  background-color: white !important;
  font-weight: 600;
  font-size: 32px;
}

.card-footer{
  border-top: none !important;
  background-color: white !important;
}

.card{
  border: none;
}

.btn-primary{
  color: $primaryColor !important;
}

.btn-link {
  color: $primaryColor !important;
}

.input-group input{
  border-right: none !important;
}

.input-group-text{
  background-color: white !important;
}

.btn-outline-primary{
  &:hover{
    color: white !important;
  }

}
.tooltip{
  --bs-tooltip-bg: rgba(249, 80, 49, 1) !important;
  color: #333333;
}

.tooltip-inner {
  color: var(--grayscale-label, #828282);
  box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.12);
  background: #FFF !important;
  min-width: 350px !important;
  text-align: left;
  border-radius: 8px;
  padding: 10px;
}

.md-drppicker .ranges ul li button.active {
  background-color: #FBBC05 !important;
  color: #fff;
}

.md-drppicker .btn {
  background-color: #FBBC05 !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  margin-left: 5px !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 170% !important;
  padding: 4px 20px !important;
  text-transform: capitalize !important;
}
.md-drppicker .btn.btn-default{
  background-color: #828282 !important;
  color: #FFFFFF !important;
  border-radius: 4px !important;
  margin-right: 5px !important;
}
.md-drppicker td.active, .md-drppicker td.active:hover {
    background-color: #FBBC05 !important;
    border-color: transparent !important;
    color: #fff !important;
}
.md-drppicker .in-range {
    background-color: #F8ECC8 !important;
    border-color: transparent !important;
    color: #000 !important;
}
.md-drppicker td .off .in-range {
    background-color: #F8ECC8 !important;
    border-color: transparent !important;
    color: #999 !important;
}
.md-drppicker td.available {
  color: #222222;
  font-style: normal;
  font-weight: 400;
}
.md-drppicker td.available:hover {
  color: #000 !important;
}
.md-drppicker td.available.off {
  color: #828282;
  font-style: normal;
  font-weight: 400;
}
